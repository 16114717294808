<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-refresh" type="success" @click="fetchData">
          刷新
        </el-button>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
        <el-button
          v-if="queryForm.pid !== '0'"
          icon="el-icon-back"
          @click="handleReturn"
        >
          返回
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        align="center"
        label="名称"
        prop="dictName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="类型"
        prop="dictType"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          <span v-if="queryForm.pid !== '0'">{{ row.dictType }}</span>
          <el-button v-else type="text" @click.stop="childHandle(row)">
            {{ row.dictType }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="queryForm.pid !== '0'"
        align="center"
        label="字典值"
        prop="dictValue"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="排序"
        prop="sort"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="备注"
        prop="remark"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="创建时间"
        prop="createDate"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <dict-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { page, doDelete } from '@/api/sysDict'
  import DictEdit from './components/DictEdit'

  export default {
    name: 'Dict',
    components: {
      DictEdit,
    },
    data() {
      return {
        height: this.$baseTableHeight(1),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          pid: '0',
          dictType: '',
          page: 1,
          limit: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      initQuery() {
        this.queryForm = {
          pid: '0',
          dictType: '',
          page: 1,
          limit: 10,
        }
      },
      childHandle(row) {
        this.initQuery()
        this.queryForm.pid = row.id
        this.queryForm.dictType = row.dictType
        this.fetchData()
      },
      handleAdd() {
        this.$refs['edit'].showEdit(null, this.queryForm)
      },
      handleReturn() {
        this.initQuery()
        this.queryForm.pid = '0'
        this.fetchData()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row, null)
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            await doDelete([row.id])
            this.$baseMessage('删除成功', 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id)
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              await doDelete(ids)
              this.$baseMessage(
                '删除成功',
                'success',
                'vab-hey-message-success'
              )
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

import request from '@/utils/request'

export const getProvince = (params, cfg) =>
  request.get('/chinaregion/getProvince', { ...cfg, params })

export const getCity = (params, cfg) =>
  request.get('/chinaregion/getCity', { ...cfg, params })

export const getCounty = (params, cfg) =>
  request.get('/chinaregion/getCounty', { ...cfg, params })

export const getTown = (params, cfg) =>
  request.get('/chinaregion/getTown', { ...cfg, params })

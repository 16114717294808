<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="状态">
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="queryForm.startTime"
              placeholder="开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            至
            <el-date-picker
              v-model="queryForm.endTime"
              placeholder="结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
        <el-button icon="el-icon-download" @click="handleExport">
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      class="tableHasChildren"
      :data="list"
      :height="height"
      row-key="id"
      :tree-props="treeProps"
    >
      <el-table-column
        v-for="(item, idx) in tableConfig"
        :key="idx"
        :align="item.align"
        :formatter="item.formatter"
        :header-align="item.headerAlign"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            v-if="row.status == 0"
            type="text"
            @click="handleStatusChange(row.id, false)"
          >
            禁用
          </el-button>
          <el-button
            v-else
            type="text"
            @click="handleStatusChange(row.id, true)"
          >
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <equipment-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import { getList, disable, enable, exportExcel } from '@/api/dict/equipment'
  import { getDict } from '@/api/dict/industry'
  import equipmentEdit from './components/EquipmentEdit'
  import _ from 'lodash'
  export default {
    name: 'EquipmentDictionary',
    components: { equipmentEdit },
    data() {
      return {
        tableConfig: [
          {
            prop: 'id',
            label: '序列号',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'industryId',
            label: '行业',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              return this.getIndustryName(v)
            },
          },
          {
            prop: 'dictValue',
            label: '品牌/型号',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'status',
            label: '状态',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              switch (v) {
                case 0:
                  return '启用'
                case 1:
                  return '停用'
                default:
                  return '删除'
              }
            },
          },
          {
            prop: 'remark',
            label: '备注',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'updateDate',
            label: '修改时间',
            headerAlign: 'center',
            align: 'center',
          },
        ],
        statusList: [
          {
            value: '0',
            label: '启用',
          },
          {
            value: '1',
            label: '停用',
          },
          {
            value: '2',
            label: '删除',
          },
        ],
        fold: false,
        height: this.$baseTableHeight(1),
        imgShow: true,
        list: [],
        listLoading: true,
        // layout: 'total, sizes, prev, pager, next, jumper',
        treeProps: { children: 'children', hasChildren: 'hasChildren' },
        total: 0,
        selectRows: '',
        queryForm: {},
        dictMap: {},
      }
    },
    mounted() {
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleExport() {
        let params = _.cloneDeep(this.queryForm)
        delete params.page
        delete params.limit
        let link = exportExcel(params)
        window.open(link)
      },
      handleQuery() {
        // this.queryForm.page = 1
        this.fetchData()
      },
      getIndustryName(id) {
        if (this.dictMap[id]) return this.dictMap[id].longText
        else return ''
      },
      async getDictMap() {
        const { code, data, msg } = await getDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        let maps = {}
        let values = data.map((v) => {
          let pids = v.pids.split(',').map((n) => Number(n))
          let obj = {
            id: v.id,
            dictValue: v.dictValue,
            pids,
          }
          return (maps[v.id] = obj)
        })
        values.forEach((v) => {
          let names = v.pids
            .map((n) => {
              if (n === 0) return ''
              let i = maps[n]
              if (i) return i.dictValue
              else return ''
            })
            .filter((o) => o !== '')
          names.push(v.dictValue)
          this.$set(v, 'longText', names.join('-'))
        })
        this.$set(this, 'dictMap', maps)
      },
      async handleStatusChange(id, b) {
        this.listLoading = true
        let func = b ? enable : disable
        const { code, msg } = await func({ id })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        }
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        await this.getDictMap()
        const { data } = await getList(this.queryForm)
        this.list = data
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss">
  .tableHasChildren {
    .cell > .el-table__expand-icon {
      position: absolute;
      top: 1em;
      left: 1em;
    }
    .cell > .el-table__placeholder {
      display: none;
    }
  }
</style>

<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="名称" prop="dictName">
        <el-input v-model="form.dictName" />
      </el-form-item>
      <el-form-item v-if="form.pid === '0'" label="类型" prop="dictType">
        <el-input v-model="form.dictType" />
      </el-form-item>
      <el-form-item v-if="form.pid !== '0'" label="值" prop="dictValue">
        <el-input v-model="form.dictValue" />
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model="form.sort" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doPost, doPut } from '@/api/sysDict'
  import { isNumber } from '@/utils/validate'

  export default {
    name: 'ParamsEdit',
    data() {
      const validateNumber = (rule, value, callback) => {
        if (!isNumber(value)) callback(new Error('请输入大于0的数字'))
        else callback()
      }
      return {
        form: {
          id: '',
          pid: '0',
          dictName: '',
          dictType: '',
          dictValue: '',
          sort: '',
          remark: '',
        },
        rules: {
          dictName: [
            { required: true, trigger: 'blur', message: '请输入编码' },
          ],
          dictType: [
            { required: true, trigger: 'blur', message: '请输入编码' },
          ],
          dictValue: [{ required: true, trigger: 'blur', message: '请输入值' }],
          sort: [
            { required: true, trigger: 'blur', message: '请输入排序' },
            {
              required: true,
              trigger: 'blur',
              validator: validateNumber,
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row, form) {
        if (!row) {
          console.log(form)
          this.title = '添加'
          this.form.pid = form.pid
          this.form.dictType = form.dictType
        } else {
          this.title = '编辑'
          this.form.id = row.id
          this.form.pid = row.pid
          this.form.dictName = row.dictName
          this.form.dictType = row.dictType
          this.form.dictValue = row.dictValue
          this.form.sort = row.sort
          this.form.remark = row.remark
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.id) {
              await doPut(this.form)
              this.$baseMessage(
                '编辑成功',
                'success',
                'vab-hey-message-success'
              )
            } else {
              await doPost(this.form)
              this.$baseMessage(
                '添加成功',
                'success',
                'vab-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { lg: 14, md: 11, sm: 24, xl: 14, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { lg: 9, md: 12, sm: 24, xl: 9, xs: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "login-form",
                  attrs: {
                    "label-position": "left",
                    model: _vm.form,
                    rules: _vm.rules,
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("hello !")]),
                  _c("div", { staticClass: "title-tips" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.translateTitle("欢迎来到")) +
                        _vm._s(_vm.title) +
                        "！ "
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { prop: "username" },
                    },
                    [
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        attrs: {
                          placeholder: _vm.translateTitle("请输入用户名"),
                          tabindex: "1",
                          type: "text",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function () {
                              return [
                                _c("vab-icon", {
                                  attrs: { icon: "user-line" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "username",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          placeholder: _vm.translateTitle("请输入密码"),
                          tabindex: "2",
                          type: _vm.passwordType,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prefix",
                              fn: function () {
                                return [
                                  _c("vab-icon", {
                                    attrs: { icon: "lock-line" },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            _vm.passwordType === "password"
                              ? {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("vab-icon", {
                                        staticClass: "show-password",
                                        attrs: { icon: "eye-off-line" },
                                        on: { click: _vm.handlePassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "suffix",
                                  fn: function () {
                                    return [
                                      _c("vab-icon", {
                                        staticClass: "show-password",
                                        attrs: { icon: "eye-line" },
                                        on: { click: _vm.handlePassword },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "captcha" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder:
                            _vm.translateTitle("验证码") + _vm.previewText,
                          tabindex: "3",
                          type: "text",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prefix",
                            fn: function () {
                              return [
                                _c("vab-icon", {
                                  attrs: { icon: "barcode-box-line" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.form.captcha,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "captcha",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.captcha",
                        },
                      }),
                      _c("el-image", {
                        staticClass: "code",
                        attrs: { src: _vm.codeUrl },
                        on: { click: _vm.changeCode },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "login-btn",
                      attrs: { loading: _vm.loading, type: "primary" },
                      on: { click: _vm.handleLogin },
                    },
                    [_vm._v(" " + _vm._s(_vm.translateTitle("登录")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { lg: 1, md: 1, sm: 24, xl: 1, xs: 24 } }, [
            _c("div", { staticStyle: { color: "transparent" } }, [
              _vm._v("占位符"),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
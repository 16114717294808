import request from '@/utils/request'
import qs from 'qs'
import { baseURL } from '@/config'
import { getToken } from '@/utils/token'

export function getList(params) {
  return request({
    url: '/dictequipment/getList',
    method: 'get',
    params,
  })
}

export function getDict(params) {
  return request({
    url: '/dictequipment/getDict',
    method: 'get',
    params,
  })
}

export function getData(id, params) {
  return request({
    url: `/dictequipment/${id}`,
    method: 'get',
    params,
  })
}

export function getNewDict(params) {
  return request({
    url: '/dictequipment/getDict4processed',
    method: 'get',
    params,
  })
}

export function save(data) {
  return request({
    url: '/dictequipment',
    method: 'post',
    data,
  })
}

export function update(data) {
  return request({
    url: '/dictequipment',
    method: 'put',
    data,
  })
}

export function remove(data) {
  return request({
    url: '/dictequipment',
    method: 'delete',
    data,
  })
}

export function disable(params) {
  return request({
    url: '/dictequipment/disable',
    method: 'put',
    params,
  })
}

export function enable(params) {
  return request({
    url: '/dictequipment/enable',
    method: 'put',
    params,
  })
}

export function exportExcel(params) {
  let token = getToken()
  let str = qs.stringify({ ...params, token })
  return `${baseURL}/dictequipment/export?${str}`
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "dictName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.dictName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dictName", $$v)
                  },
                  expression: "form.dictName",
                },
              }),
            ],
            1
          ),
          _vm.form.pid === "0"
            ? _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "dictType" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.dictType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dictType", $$v)
                      },
                      expression: "form.dictType",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.pid !== "0"
            ? _c(
                "el-form-item",
                { attrs: { label: "值", prop: "dictValue" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.dictValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dictValue", $$v)
                      },
                      expression: "form.dictValue",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "sort" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.sort,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sort", $$v)
                  },
                  expression: "form.sort",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
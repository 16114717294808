<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="49px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="学历">
            <el-select
              v-model="queryForm.qualification"
              clearable
              placeholder="请选择学历"
            >
              <el-option
                v-for="item in dictList.qualification"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="工龄">
            <el-select
              v-model="queryForm.jobRequire"
              clearable
              placeholder="请选择工龄"
            >
              <el-option
                v-for="item in dictList.work_years"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="地区">
            <el-select
              v-model="provinceId"
              clearable
              placeholder="请选择省份"
              @change="provinceChanged"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.province_id"
                :label="item.province_name"
                :value="item.province_id"
              />
            </el-select>
            <el-select
              v-model="cityId"
              clearable
              :disabled="!provinceId"
              placeholder="请选择地市"
              @change="cityChanged"
            >
              <el-option
                v-for="item in cityList"
                :key="item.city_id"
                :label="item.city_name"
                :value="item.city_id"
              />
            </el-select>
            <el-select
              v-model="countyId"
              clearable
              :disabled="!cityId"
              placeholder="请选择区县"
              @change="countyChanged"
            >
              <el-option
                v-for="item in countyList"
                :key="item.county_id"
                :label="item.county_name"
                :value="item.county_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="queryForm.auditStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(value, key) in statusMap"
                :key="key"
                :label="value"
                :value="key"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-refresh" type="success" @click="fetchData">
          刷新
        </el-button>
        <el-button icon="el-icon-download" @click="handleExport">
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        v-for="(item, idx) in tableConfig"
        :key="idx"
        :align="item.align"
        :formatter="item.formatter"
        :header-align="item.headerAlign"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.overflowTooltip"
        :type="item.type"
        :width="item.width"
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button
            v-if="row.auditStatus == 2"
            type="text"
            @click="viewHandle(row.id, true)"
          >
            审核
          </el-button>
          <el-button v-else type="text" @click="viewHandle(row.id)">
            详情
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <post-viewer ref="viewer" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { page, exportExcel } from '@/api/recruit/post'
  import { list as getDictList } from '@/api/sysDict'
  import { getProvince, getCity, getCounty } from '@/api/dict/chinaregion'
  import postViewer from './components/PostViewer'
  import _ from 'lodash'

  export default {
    name: 'RecruitPost',
    components: { postViewer },
    data() {
      return {
        height: this.$baseTableHeight(2),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        tableConfig: [
          {
            prop: 'id',
            label: '岗位id',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'post',
            label: '职业名称',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'areaCode',
            label: '区域',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'workAddress',
            label: '地址',
            headerAlign: 'center',
            align: 'center',
            overflowTooltip: true,
          },
          {
            prop: 'qualification',
            label: '学历',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.qualification && this.dictMap.qualification[v])
                  return this.dictMap.qualification[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'jobRequire',
            label: '经验',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.work_years && this.dictMap.work_years[v])
                  return this.dictMap.work_years[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'minSalary',
            label: '薪资',
            headerAlign: 'center',
            align: 'center',
            formatter: (row) => {
              if (!row.minSalary) return '面议'
              else
                return `${Number(row.minSalary).toFixed(0)}K - ${Number(
                  row.maxSalary
                ).toFixed(0)}K`
            },
          },
          {
            prop: 'username',
            label: '创建人',
            headerAlign: 'center',
            align: 'center',
            formatter: (row) => {
              return row.username ? row.username : row.nickName
            },
          },
          {
            prop: 'createTime',
            label: '创建时间',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'auditStatus',
            label: '审核状态',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              switch (v) {
                case 1:
                  return '待审核'
                case 2:
                  return '审核通过'
                default:
                  return '审核不通过'
              }
            },
          },
        ],
        queryForm: {
          status: '',
          page: 1,
          limit: 10,
        },
        provinceList: [],
        cityList: [],
        countyList: [],
        provinceId: null,
        cityId: null,
        countyId: null,
        dictMap: {},
        dictList: {},
        statusMap: {
          1: '待审核',
          2: '审核通过',
          3: '审核不通过',
        },
      }
    },
    mounted() {
      this.fetchData()
      this.getDict('qualification')
      this.getDict('nation')
      this.getDict('native_place')
      this.getDict('gender')
      this.getDict('work_status')
      this.getDict('work_years')
      this.getProvince()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      async getDict(name) {
        const { code, data, msg } = await getDictList({ dictType: name })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else if (data.length) {
          let map = {}
          let items = []
          data.forEach((d) => {
            if (d.pid !== '0') {
              map[d.dictValue] = d.dictName
              items.push(d)
            }
          })
          this.$set(this.dictMap, name, map)
          this.$set(this.dictList, name, items)
        }
      },
      async getProvince() {
        const { code, data, msg } = await getProvince()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'provinceList', data)
        }
      },
      async getCity(provinceId) {
        const { code, data, msg } = await getCity({ provinceId })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'cityList', data)
        }
      },
      async getCounty(cityId) {
        const { code, data, msg } = await getCounty({ cityId })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'countyList', data)
        }
      },
      provinceChanged(item) {
        this.cityList = []
        this.countyList = []
        this.cityId = null
        this.countyId = null
        this.getCity(item)
        this.$set(this.queryForm, 'areaCode', item)
      },
      cityChanged(item) {
        this.countyList = []
        this.countyId = null
        if (item) {
          this.getCounty(item)
          this.$set(this.queryForm, 'areaCode', item)
        } else {
          this.$set(this.queryForm, 'areaCode', this.provinceId)
        }
      },
      countyChanged(item) {
        if (item) {
          this.$set(this.queryForm, 'areaCode', item)
        } else {
          this.$set(this.queryForm, 'areaCode', this.cityId)
        }
      },
      viewHandle(id, isCheck) {
        this.$refs['viewer'].init(id, isCheck)
      },
      handleExport() {
        let params = _.cloneDeep(this.queryForm)
        delete params.page
        delete params.limit
        let link = exportExcel(params)
        window.open(link)
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

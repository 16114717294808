<template>
  <el-dialog :title="title" :visible.sync="visible" width="30%" @close="close">
    <el-form ref="form" label-width="100px" :model="dataForm" :rules="rules">
      <el-form-item label="名称" prop="dictValue">
        <el-input v-model="dataForm.dictValue" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="dataForm.remark" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
  import { save, update, getData } from '@/api/dict/welfare'
  import _ from 'lodash'
  export default {
    name: 'WelfareEdit',
    data() {
      return {
        dataForm: {
          id: null,
        },
        visible: false,
        rules: {
          dictValue: [
            { required: true, trigger: 'blur', message: '请输入名称' },
          ],
        },
      }
    },
    computed: {
      title() {
        if (!this.dataForm.id) return '新增'
        else return '修改'
      },
    },
    methods: {
      showEdit(row) {
        if (row && row.id) {
          // this.form = _.cloneDeep(row)
          this.getData(row.id)
        }
        this.visible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.dataForm = this.$options.data().dataForm
        this.visible = false
      },
      async getData(id) {
        const { code, data, msg } = await getData(id)
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this, 'dataForm', data)
      },
      async checkForm(name) {
        return new Promise((resolve, reject) => {
          this.$refs[name].validate((valid) => {
            return valid ? resolve(true) : reject(new Error('请按表单提示修改'))
          })
        })
      },
      async save() {
        const ret = await this.checkForm('form')
        if (ret) {
          let func = this.dataForm.id ? update : save
          let form = _.cloneDeep(this.dataForm)
          const { code, msg } = await func(form)
          if (code !== 0) {
            this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          } else {
            this.$baseMessage(
              `${this.title}操作成功`,
              'success',
              'vab-hey-message-success'
            )
          }
          this.$emit('fetch-data')
          this.close()
        }
      },
    },
  }
</script>

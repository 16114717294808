var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.visible, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "100px",
            model: _vm.dataForm,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "dictValue" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.dataForm.dictValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "dictValue", $$v)
                  },
                  expression: "dataForm.dictValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "别称" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入别称" },
                model: {
                  value: _vm.dataForm.alias,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "alias", $$v)
                  },
                  expression: "dataForm.alias",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联设备" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataForm.equipmentFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataForm, "equipmentFlag", $$v)
                    },
                    expression: "dataForm.equipmentFlag",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("否")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.dataForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataForm, "remark", $$v)
                  },
                  expression: "dataForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import qs from 'qs'
import { getToken } from '@/utils/token'
import { baseURL } from '@/config'

export function exportExcle(data, url) {
  console.log(data, url)
  let params = qs.stringify({
    token: getToken(),
    ...data,
  })
  console.log(`${baseURL}${url}?${params}`)
  window.location.href = `${baseURL}${url}?${params}`
}

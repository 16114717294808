import request from '@/utils/request'
import qs from 'qs'
import { baseURL } from '@/config'
import { getToken } from '@/utils/token'

export const page = (params, cfg) =>
  request.get('/backstageSelina/findSelinaPage', { ...cfg, params })
export const remove = (data, cfg) =>
  request.delete('/backstageSelina', { ...cfg, data })
export const getData = (params, cfg) =>
  request.get('/backstageSelina/findSelinaCheckView', { ...cfg, params })
export const check = (data, cfg) =>
  request.post('/backstageSelina/checkSelina', data, cfg)
export function exportExcel(params) {
  let token = getToken()
  let str = qs.stringify({ ...params, token })
  return `${baseURL}/backstageSelina/export?${str}`
}

<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="49px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="状态">
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(value, key) in statusMap"
                :key="key"
                :label="value"
                :value="key"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-refresh" type="success" @click="fetchData">
          刷新
        </el-button>
        <el-button icon="el-icon-download" @click="handleExport">
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        align="center"
        label="ID"
        prop="id"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="反馈时间"
        prop="createDate"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="反馈内容"
        prop="content"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="状态"
        prop="status"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ statusMap[row.status] }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="姓名"
        prop="proposerName"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.proposerName ? row.proposerName : row.fillName }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账号/电话"
        prop="proposerPhone"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="230"
      >
        <template #default="{ row }">
          <el-button
            v-if="row.status < 1"
            type="text"
            @click="handleDeal(row, 1)"
          >
            未回复
          </el-button>
          <el-button
            v-if="row.status < 2"
            type="text"
            @click="handleDeal(row, 2)"
          >
            已回复
          </el-button>
          <el-button
            v-if="row.status < 3"
            type="text"
            @click="handleDeal(row, 3)"
          >
            未解决
          </el-button>
          <el-button
            v-if="row.status < 4"
            type="text"
            @click="handleDeal(row, 4)"
          >
            已解决
          </el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { page, doDelete, doPut } from '@/api/feedback'
  import { exportExcle } from '@/utils/export'

  export default {
    name: 'Feedback',
    data() {
      return {
        height: this.$baseTableHeight(2),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        queryForm: {
          status: '',
          page: 1,
          limit: 10,
        },
        statusMap: {
          1: '未回复',
          2: '已回复',
          3: '未解决',
          4: '已解决',
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async handleDeal(params, status) {
        await doPut(Object.assign(params, { status: status }))
        this.$baseMessage('修改成功', 'success', 'vab-hey-message-success')
        await this.fetchData()
      },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            await doDelete([parseInt(row.id)])
            this.$baseMessage('删除成功', 'success', 'vab-hey-message-success')
            await this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id)
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              await doDelete(ids)
              this.$baseMessage(
                '删除成功',
                'success',
                'vab-hey-message-success'
              )
              await this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
          }
        }
      },
      handleExport() {
        exportExcle(this.queryForm, '/feedback/export')
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

import request from '@/utils/request'

export function page(params) {
  return request({
    url: '/sys/dict/page',
    method: 'get',
    params,
  })
}

export function list(params) {
  return request({
    url: '/sys/dict/list',
    method: 'get',
    params,
  })
}

export function doDelete(data) {
  return request({
    url: '/sys/dict',
    method: 'delete',
    data,
  })
}

export function doPost(data) {
  return request({
    url: '/sys/dict',
    method: 'post',
    data,
  })
}

export function doPut(data) {
  return request({
    url: '/sys/dict',
    method: 'put',
    data,
  })
}

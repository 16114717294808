<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="状态">
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="queryForm.startTime"
              placeholder="开始时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            至
            <el-date-picker
              v-model="queryForm.endTime"
              placeholder="结束时间"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
        <el-button icon="el-icon-download" @click="handleExport">
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        v-for="(item, idx) in tableConfig"
        :key="idx"
        :align="item.align"
        :formatter="item.formatter"
        :header-align="item.headerAlign"
        :label="item.label"
        :prop="item.prop"
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            v-if="row.status == 0"
            type="text"
            @click="handleStatusChange(row.id, false)"
          >
            禁用
          </el-button>
          <el-button
            v-else
            type="text"
            @click="handleStatusChange(row.id, true)"
          >
            启用
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <welfare-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>
<script>
  import { page, disable, enable, exportExcel } from '@/api/dict/welfare'
  import welfareEdit from './components/WelfareEdit'
  import _ from 'lodash'
  export default {
    name: 'WelfareDictionary',
    components: { welfareEdit },
    data() {
      return {
        tableConfig: [
          {
            prop: 'id',
            label: '序列号',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'dictValue',
            label: '名称',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'status',
            label: '状态',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              switch (v) {
                case 0:
                  return '启用'
                case 1:
                  return '停用'
                default:
                  return '删除'
              }
            },
          },
          {
            prop: 'remark',
            label: '备注',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'updateDate',
            label: '修改时间',
            headerAlign: 'center',
            align: 'center',
          },
        ],
        statusList: [
          {
            value: '0',
            label: '启用',
          },
          {
            value: '1',
            label: '停用',
          },
          {
            value: '2',
            label: '删除',
          },
        ],
        fold: false,
        height: this.$baseTableHeight(2),
        imgShow: true,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          page: 1,
          limit: 10,
        },
      }
    },
    mounted() {
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleExport() {
        let params = _.cloneDeep(this.queryForm)
        delete params.page
        delete params.limit
        let link = exportExcel(params)
        window.open(link)
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async handleStatusChange(id, b) {
        this.listLoading = true
        let func = b ? enable : disable
        const { code, msg } = await func({ id })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        }
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

import request from '@/utils/request'
import qs from 'qs'
import { baseURL } from '@/config'
import { getToken } from '@/utils/token'

export const page = (params, cfg) =>
  request.get('/backstagePost/findPostPage', { ...cfg, params })
export const remove = (data, cfg) =>
  request.delete('/backstagePost', { ...cfg, data })
export const getData = (params, cfg) =>
  request.get('/backstagePost/findPostCheckView', { ...cfg, params })
export const check = (data, cfg) =>
  request.post('/backstagePost/checkPost', data, cfg)
export function exportExcel(params) {
  let token = getToken()
  let str = qs.stringify({ ...params, token })
  return `${baseURL}/backstagePost/export?${str}`
}

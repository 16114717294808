<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="49px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="学历">
            <el-select
              v-model="queryForm.qualification"
              clearable
              placeholder="请选择学历"
            >
              <el-option
                v-for="item in dictList.qualification"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="工龄">
            <el-select
              v-model="queryForm.workYears"
              clearable
              placeholder="请选择工龄"
            >
              <el-option
                v-for="item in dictList.work_years"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="民族">
            <el-select
              v-model="queryForm.nation"
              clearable
              placeholder="请选择民族"
            >
              <el-option
                v-for="item in dictList.nation"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="性别">
            <el-select
              v-model="queryForm.gender"
              clearable
              placeholder="请选择性别"
            >
              <el-option
                v-for="item in dictList.gender"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="地区">
            <el-select
              v-model="provinceId"
              clearable
              placeholder="请选择省份"
              @change="provinceChanged"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.province_id"
                :label="item.province_name"
                :value="item.province_id"
              />
            </el-select>
            <el-select
              v-model="cityId"
              clearable
              :disabled="!provinceId"
              placeholder="请选择地市"
              @change="cityChanged"
            >
              <el-option
                v-for="item in cityList"
                :key="item.city_id"
                :label="item.city_name"
                :value="item.city_id"
              />
            </el-select>
            <el-select
              v-model="countyId"
              clearable
              :disabled="!cityId"
              placeholder="请选择区县"
              @change="countyChanged"
            >
              <el-option
                v-for="item in countyList"
                :key="item.county_id"
                :label="item.county_name"
                :value="item.county_id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="queryForm.status"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="(value, key) in statusMap"
                :key="key"
                :label="value"
                :value="key"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="籍贯">
            <el-select
              v-model="queryForm.nativePlace"
              clearable
              placeholder="请选择籍贯"
            >
              <el-option
                v-for="item in dictList.native_place"
                :key="item.dictValue"
                :label="item.dictName"
                :value="item.dictValue"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-refresh" type="success" @click="fetchData">
          刷新
        </el-button>
        <el-button icon="el-icon-download" @click="handleExport">
          导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        v-for="(item, idx) in tableConfig"
        :key="idx"
        :align="item.align"
        :formatter="item.formatter"
        :header-align="item.headerAlign"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="item.overflowTooltip"
        :type="item.type"
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="viewHandle(row.id, true)">
            审核
          </el-button>
          <el-button type="text" @click="viewHandle(row.id)">详情</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <resume-viewer ref="viewer" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { page, exportExcel } from '@/api/recruit/resume'
  import { list as getDictList } from '@/api/sysDict'
  import { getDict as getIndustryDict } from '@/api/dict/industry'
  import { getDict as getPositionDict } from '@/api/dict/position'
  import { getProvince, getCity, getCounty } from '@/api/dict/chinaregion'
  import resumeViewer from './components/ResumeViewer'
  import _ from 'lodash'

  export default {
    name: 'RecruitResume',
    components: { resumeViewer },
    data() {
      return {
        height: this.$baseTableHeight(2),
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        tableConfig: [
          {
            prop: 'id',
            label: '简历id',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'name',
            label: '姓名',
            headerAlign: 'center',
            align: 'center',
          },
          {
            prop: 'brithDate',
            label: '出生年月',
            headerAlign: 'center',
            align: 'center',
            width: 120,
          },
          {
            prop: 'nation',
            label: '民族',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.nation && this.dictMap.nation[v])
                  return this.dictMap.nation[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'nativePlace',
            label: '籍贯',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.native_place && this.dictMap.native_place[v])
                  return this.dictMap.native_place[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'qualification',
            label: '学历',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.qualification && this.dictMap.qualification[v])
                  return this.dictMap.qualification[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'workYears',
            label: '工龄',
            headerAlign: 'center',
            align: 'center',
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.work_years && this.dictMap.work_years[v])
                  return this.dictMap.work_years[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'positionId',
            label: '岗位',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (row, c, v) => {
              if (v) {
                if (this.dictMap.position && this.dictMap.position[v])
                  return this.dictMap.position[v]
                else return '-'
              } else {
                return row.otherPosition || '-'
              }
            },
          },
          {
            prop: 'workStatus',
            label: '工作状态',
            headerAlign: 'center',
            align: 'center',
            width: 120,
            formatter: (r, c, v) => {
              if (v) {
                if (this.dictMap.work_status && this.dictMap.work_status[v])
                  return this.dictMap.work_status[v]
                else return '-'
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'workPlaceName',
            label: '意向地区',
            headerAlign: 'center',
            align: 'center',
            width: 200,
          },
          {
            prop: 'industryId',
            label: '意向行业',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            formatter: (r, c, v) => {
              if (v) {
                return this.getIndustryName(v)
              } else {
                return '不限'
              }
            },
          },
          {
            prop: 'salaryMax',
            label: '意向薪资',
            headerAlign: 'center',
            align: 'center',
            width: 120,
            formatter: (row) => {
              if (!row.salaryMin) return '面议'
              else
                return `${Number(row.salaryMin).toFixed(0)}K - ${Number(
                  row.salaryMax
                ).toFixed(0)}K`
            },
          },
          {
            prop: 'createDate',
            label: '创建时间',
            headerAlign: 'center',
            align: 'center',
            width: 160,
          },
          {
            prop: 'status',
            label: '审核状态',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (r, c, v) => {
              switch (v) {
                case 0:
                  return '审核通过'
                default:
                  return '审核不通过'
              }
            },
          },
        ],
        queryForm: {
          status: '',
          page: 1,
          limit: 10,
        },
        provinceList: [],
        cityList: [],
        countyList: [],
        provinceId: null,
        cityId: null,
        countyId: null,
        dictMap: {},
        dictList: {},
        industryMap: {},
        statusMap: {
          0: '审核通过',
          1: '审核不通过',
        },
      }
    },
    mounted() {
      this.fetchData()
      this.getDict('qualification')
      this.getDict('nation')
      this.getDict('native_place')
      this.getDict('gender')
      this.getDict('work_status')
      this.getDict('work_years')
      this.getProvince()
      this.getIndustryDict()
      this.getPositionDict()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      async getPositionDict() {
        const { code, data, msg } = await getPositionDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        let { list, map } = data
        this.$set(this.dictMap, 'position', map)
        this.$set(this.dictList, 'position', list)
      },
      async getIndustryDict() {
        const { code, data, msg } = await getIndustryDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        let maps = {}
        let values = data.map((v) => {
          let pids = v.pids.split(',').map((n) => Number(n))
          let obj = {
            id: v.id,
            dictValue: v.dictValue,
            pids,
          }
          return (maps[v.id] = obj)
        })
        values.forEach((v) => {
          let names = v.pids
            .map((n) => {
              if (n === 0) return ''
              let i = maps[n]
              if (i) return i.dictValue
              else return ''
            })
            .filter((o) => o !== '')
          names.push(v.dictValue)
          this.$set(v, 'longText', names.join('-'))
        })
        this.$set(this, 'industryMap', maps)
      },
      getIndustryName(id) {
        if (this.industryMap[id]) return this.industryMap[id].longText
        else return ''
      },
      async getDict(name) {
        const { code, data, msg } = await getDictList({ dictType: name })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else if (data.length) {
          let map = {}
          let items = []
          data.forEach((d) => {
            if (d.pid !== '0') {
              map[d.dictValue] = d.dictName
              items.push(d)
            }
          })
          this.$set(this.dictMap, name, map)
          this.$set(this.dictList, name, items)
        }
      },
      async getProvince() {
        const { code, data, msg } = await getProvince()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'provinceList', data)
        }
      },
      async getCity(provinceId) {
        const { code, data, msg } = await getCity({ provinceId })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'cityList', data)
        }
      },
      async getCounty(cityId) {
        const { code, data, msg } = await getCounty({ cityId })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else {
          this.$set(this, 'countyList', data)
        }
      },
      provinceChanged(item) {
        this.cityList = []
        this.countyList = []
        this.cityId = null
        this.countyId = null
        this.getCity(item)
        this.$set(this.queryForm, 'workPlace', item)
      },
      cityChanged(item) {
        this.countyList = []
        this.countyId = null
        if (item) {
          this.getCounty(item)
          this.$set(this.queryForm, 'workPlace', item)
        } else {
          this.$set(this.queryForm, 'workPlace', this.provinceId)
        }
      },
      countyChanged(item) {
        if (item) {
          this.$set(this.queryForm, 'workPlace', item)
        } else {
          this.$set(this.queryForm, 'workPlace', this.cityId)
        }
      },
      viewHandle(id, isCheck) {
        this.$refs['viewer'].init(id, isCheck)
      },
      handleExport() {
        let params = _.cloneDeep(this.queryForm)
        delete params.page
        delete params.limit
        let link = exportExcel(params)
        window.open(link)
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

<template>
  <el-dialog
    custom-class="drawerBox"
    :title="title"
    :visible.sync="visible"
    width="500px"
    @close="close"
  >
    <div class="drawerPage">
      <div class="panelBody">
        <el-form>
          <h3 class="header blue">求职意向</h3>
          <div class="mine_table">
            <el-form-item label="行业">
              {{ getIndustryName(dataForm.industryId) }}
            </el-form-item>
            <el-form-item v-if="dataForm.positionId == 0" label="岗位">
              {{ dataForm.otherPosition || '-' }}
            </el-form-item>
            <el-form-item v-else label="岗位">
              {{
                dictMap.position ? dictMap.position[dataForm.positionId] : '-'
              }}
            </el-form-item>
            <el-form-item label="熟悉机型">
              {{ getEquipmentName(dataForm) }}
            </el-form-item>
            <el-form-item label="期望工作地">
              {{ dataForm.workPlaceName }}
            </el-form-item>
            <el-form-item v-if="dataForm.salaryMin == 0" label="薪资">
              面议
            </el-form-item>
            <el-form-item v-else label="薪资">
              {{ dataForm.salaryMin }}K - {{ dataForm.salaryMax }}K
            </el-form-item>
            <el-form-item label="工作状态">
              {{
                dictMap.work_status
                  ? dictMap.work_status[dataForm.workStatus]
                  : '-'
              }}
            </el-form-item>
          </div>
        </el-form>
        <el-form>
          <h3 class="header blue">个人信息</h3>
          <div class="mine_table">
            <el-form-item label="ID">{{ dataForm.id }}</el-form-item>
            <el-form-item label="姓名">{{ dataForm.name }}</el-form-item>
            <el-form-item label="照片">
              <img
                v-if="dataForm.photo"
                height="50"
                :src="dataForm.photo"
                width="50"
              />
            </el-form-item>
            <el-form-item label="性别">
              {{ dictMap.gender ? dictMap.gender[dataForm.gender] : '-' }}
            </el-form-item>
            <el-form-item label="民族">
              {{ dictMap.nation ? dictMap.nation[dataForm.nation] : '-' }}
            </el-form-item>
            <el-form-item label="出生年月">
              {{ dataForm.brithDate }}
            </el-form-item>
            <el-form-item label="籍贯">
              {{
                dictMap.native_place
                  ? dictMap.native_place[dataForm.nativePlace]
                  : '-'
              }}
            </el-form-item>
            <el-form-item label="学历">
              {{
                dictMap.qualification
                  ? dictMap.qualification[dataForm.qualification]
                  : '-'
              }}
            </el-form-item>
            <el-form-item label="工龄">
              {{
                dictMap.work_years
                  ? dictMap.work_years[dataForm.workYears]
                  : '-'
              }}
            </el-form-item>
          </div>
        </el-form>
        <el-form v-if="!isCheck">
          <h3 class="header blue">审核状态</h3>
          <div class="mine_table">
            <el-form-item label="审核状态">
              {{ dataForm.status == 0 ? '审核通过' : '审核不通过' }}
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div v-if="isCheck" class="panelFoot">
        <el-form ref="form" :model="checkForm">
          <el-form-item label="审核意见" prop="errString">
            <el-input
              v-model="checkForm.errString"
              placeholder="请输入审核意见"
              rows="5"
              type="textarea"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="success" @click="submit(true)">审核通过</el-button>
          <el-button type="danger" @click="submit(false)">审核不通过</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { examineFault, examineSuccess, getData } from '@/api/recruit/resume'
  import { list as getDictList } from '@/api/sysDict'
  import { getDict as getIndustryDict } from '@/api/dict/industry'
  import { getDict as getPositionDict } from '@/api/dict/position'
  import { getNewDict as getEquipmentDict } from '@/api/dict/equipment'
  import _ from 'lodash'

  export default {
    name: 'ResumeViewer',
    data() {
      return {
        dataForm: {},
        checkForm: {
          id: '',
        },
        dictMap: {},
        visible: false,
        isCheck: false,
        industryMap: {},
      }
    },
    computed: {
      title() {
        if (this.isCheck) return '审核简历'
        else return '查看简历'
      },
    },
    mounted() {
      this.getDict('qualification')
      this.getDict('nation')
      this.getDict('native_place')
      this.getDict('gender')
      this.getDict('work_status')
      this.getDict('work_years')
      this.getIndustryDict()
      this.getPositionDict()
      this.getEquipmentDict()
    },
    methods: {
      async getEquipmentDict() {
        const { code, data, msg } = await getEquipmentDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this.dictMap, 'equipment', data.map)
      },
      async getPositionDict() {
        const { code, data, msg } = await getPositionDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this.dictMap, 'position', data.map)
        // this.$set(this.dictList, 'position', list)
      },
      async getIndustryDict() {
        const { code, data, msg } = await getIndustryDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        let maps = {}
        let values = data.map((v) => {
          let pids = v.pids.split(',').map((n) => Number(n))
          let obj = {
            id: v.id,
            dictValue: v.dictValue,
            pids,
          }
          return (maps[v.id] = obj)
        })
        values.forEach((v) => {
          let names = v.pids
            .map((n) => {
              if (n === 0) return ''
              let i = maps[n]
              if (i) return i.dictValue
              else return ''
            })
            .filter((o) => o !== '')
          names.push(v.dictValue)
          this.$set(v, 'longText', names.join('-'))
        })
        this.$set(this, 'industryMap', maps)
      },
      getIndustryName(id) {
        if (this.industryMap[id]) return this.industryMap[id].longText
        else return ''
      },
      getEquipmentName(row) {
        // console.log(row)
        if (!row.equipmentId || row.equipmentId == 0)
          return `${row.otherEquipmentFirst} - ${row.otherEquipmentSecond}`
        else if (
          this.dictMap.equipment &&
          this.dictMap.equipment[row.equipmentId]
        ) {
          let item = this.dictMap.equipment[row.equipmentId]
          return item.FullName ? item.FullName.join(' - ') : item.shortName
        }
        return ' - '
      },
      async getDict(name) {
        const { code, data, msg } = await getDictList({ dictType: name })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else if (data.length) {
          let map = {}
          // let items = []
          data.forEach((d) => {
            if (d.pid !== '0') {
              map[d.dictValue] = d.dictName
              // items.push(d)
            }
          })
          this.$set(this.dictMap, name, map)
          // this.$set(this.dictList, name, items)
        }
      },
      init(id, isCheck) {
        this.isCheck = isCheck
        if (id) this.getData(id)
        this.visible = true
      },
      close() {
        if (this.isCheck) this.$refs['form'].resetFields()
        this.checkForm = this.$options.data().checkForm
        this.visible = false
      },
      async getData(id) {
        const { code, data, msg } = await getData(id)
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this, 'dataForm', data)
        this.$set(this.checkForm, 'id', data.id)
      },
      async submit(status) {
        let form = _.cloneDeep(this.checkForm)
        let func = status ? examineSuccess : examineFault
        const { code, msg } = await func(form)
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        } else {
          this.$baseMessage(
            `${this.title}操作成功`,
            'success',
            'vab-hey-message-success'
          )
        }
        this.$emit('fetch-data')
        this.close()
      },
    },
  }
</script>
<style lang="scss">
  .drawerBox .el-dialog__body {
    padding: 5px 16px;
  }
  .drawerPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .panelFoot {
      width: 100%;
      padding: 20px;
      margin: 0;
      margin-top: auto;

      .btn {
        text-align: center;
      }
    }

    .panelBody {
      width: 100%;
      flex: 2;
      padding: 0 15px;
      margin: 0;
      margin-bottom: auto;

      .header {
        line-height: 28px;
        margin-bottom: 16px;
        margin-top: 18px;
        padding-bottom: 4px;
      }

      .blue {
        color: #478fca !important;
        border-bottom: 1px solid #dcebf7;
      }

      .green {
        color: #69aa46 !important;
        border-bottom: 1px solid #cce2c1;
      }

      .mine_table {
        border: 1px solid #dcebf7;
        margin-bottom: 10px;

        .textbox {
          position: relative;
          .el-form-item__label {
            display: block;
            height: 100%;
          }

          .el-form-item__content {
            overflow: auto;
            max-height: 10em;
          }
        }

        .el-form-item {
          margin-bottom: 1px;
        }

        .el-form-item__label {
          color: #336199;
          background-color: #edf3f4;
          width: 110px;
          text-align: right;
        }
        .el-form-item__content {
          margin-left: 110px;
          padding: 0 15px;
          border-bottom: 1px dotted #dcebf7;

          &::nth-last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
</style>

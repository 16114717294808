<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="用户名">
        <span>{{ form.username }}</span>
      </el-form-item>
      <el-form-item label="原密码" prop="password">
        <el-input v-model="form.password" type="password" />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input v-model="form.newPassword" type="password" />
      </el-form-item>
      <el-form-item label="确认密码" prop="comfirmPassword">
        <el-input v-model="form.comfirmPassword" type="password" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { editPwd } from '@/api/user'
  import { isPassword } from '@/utils/validate'

  export default {
    name: 'UserEdit',
    data() {
      const validatePassword = (rule, value, callback) => {
        if (!isPassword(value)) callback(new Error('密码不能少于5位'))
        else callback()
      }
      const validateComfirmPassword = (rule, value, callback) => {
        if (!isPassword(value)) callback(new Error('密码不能少于5位'))
        else if (this.form.comfirmPassword !== this.form.newPassword)
          callback(new Error('确认密码与新密码不一致'))
        else callback()
      }
      return {
        form: {
          id: '',
          username: '',
          password: '',
          newPassword: '',
          comfirmPassword: '',
        },
        rules: {
          password: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
          newPassword: [
            {
              required: true,
              trigger: 'blur',
              validator: validatePassword,
            },
          ],
          comfirmPassword: [
            {
              required: true,
              trigger: 'blur',
              validator: validateComfirmPassword,
            },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '修改密码'
          this.form.id = row.id
          this.form.username = row.username
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            await editPwd(this.form)
            this.$baseMessage(
              '密码修改成功',
              'success',
              'vab-hey-message-success'
            )
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

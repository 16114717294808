var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "drawerBox",
        size: "30%",
        title: _vm.title,
        visible: _vm.visible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("div", { staticClass: "drawerPage" }, [
        _c(
          "div",
          { staticClass: "panelBody" },
          [
            _c("el-form", [
              _c("h3", { staticClass: "header blue" }, [_vm._v("代班信息")]),
              _c(
                "div",
                { staticClass: "mine_table" },
                [
                  _c("el-form-item", { attrs: { label: "ID" } }, [
                    _vm._v(_vm._s(_vm.dataForm.id)),
                  ]),
                  _c("el-form-item", { attrs: { label: "地点" } }, [
                    _vm._v(_vm._s(_vm.dataForm.address)),
                  ]),
                  _c("el-form-item", { attrs: { label: "时间" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dataForm.startTime + "-" + _vm.dataForm.endTime
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "代班费" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.dataForm.amount
                            ? _vm.dataForm.amount + "元"
                            : "面议"
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "电话" } }, [
                    _vm._v(_vm._s(_vm.dataForm.phone)),
                  ]),
                  _c(
                    "el-form-item",
                    { staticClass: "textbox", attrs: { label: "工作内容" } },
                    [_vm._v(" " + _vm._s(_vm.dataForm.otherText) + " ")]
                  ),
                ],
                1
              ),
            ]),
            !_vm.isCheck
              ? _c("el-form", [
                  _c("h3", { staticClass: "header blue" }, [
                    _vm._v("审核状态"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mine_table" },
                    [
                      _c("el-form-item", { attrs: { label: "审核状态" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dataForm.auditStatus == 1
                                ? "待审核"
                                : _vm.dataForm.auditStatus == 2
                                ? " 审核通过"
                                : "审核不通过"
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.isCheck
          ? _c(
              "div",
              { staticClass: "panelFoot" },
              [
                _c(
                  "el-form",
                  { ref: "form", attrs: { model: _vm.checkForm } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核意见", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入审核意见",
                            rows: "5",
                            type: "textarea",
                          },
                          model: {
                            value: _vm.checkForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkForm, "remark", $$v)
                            },
                            expression: "checkForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.submit(2)
                          },
                        },
                      },
                      [_vm._v("审核通过")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.submit(3)
                          },
                        },
                      },
                      [_vm._v("审核不通过")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="24">
        <el-button icon="el-icon-refresh" type="success" @click="fetchData">
          刷新
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
    >
      <el-table-column
        align="center"
        label="用户名"
        prop="username"
        show-overflow-tooltip
      />
      <el-table-column align="center" label="状态" show-overflow-tooltip>
        <template #default="{ row }">
          <el-tag v-if="row.status === 0" type="danger">停用</el-tag>
          <el-tag v-if="row.status === 1" type="success">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="创建时间"
        prop="createDate"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="130"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEditPwd(row)">
            修改密码
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <user-edit ref="edit" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import { page } from '@/api/user'
  import UserEdit from './components/UserEdit'

  export default {
    name: 'User',
    components: {
      UserEdit,
    },
    data() {
      return {
        fold: false,
        height: this.$baseTableHeight(1),
        imgShow: true,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        queryForm: {
          page: 1,
          limit: 10,
        },
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleEditPwd(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const {
          data: { records, total },
        } = await page(this.queryForm)
        this.list = records
        this.total = parseInt(total)
        this.listLoading = false
      },
    },
  }
</script>

<template>
  <el-dialog
    custom-class="drawerBox"
    size="30%"
    :title="title"
    :visible.sync="visible"
    @close="close"
  >
    <div class="drawerPage">
      <div class="panelBody">
        <el-form>
          <h3 class="header blue">岗位信息</h3>
          <div class="mine_table">
            <el-form-item label="ID">{{ dataForm.id }}</el-form-item>
            <el-form-item v-if="dataForm.positionId == 0" label="职位名称">
              {{ dataForm.post }}
            </el-form-item>
            <el-form-item v-else label="职位名称">
              {{
                dictMap.position ? dictMap.position[dataForm.positionId] : '-'
              }}
            </el-form-item>
            <el-form-item label="区域">{{ dataForm.areaCode }}</el-form-item>
            <el-form-item label="地址">{{ dataForm.workAddress }}</el-form-item>
            <el-form-item label="学历">
              {{
                dictMap.qualification
                  ? dictMap.qualification[dataForm.qualification]
                  : '-'
              }}
            </el-form-item>
            <el-form-item label="经验">
              {{
                dictMap.work_years
                  ? dictMap.work_years[dataForm.jobRequire]
                  : '-'
              }}
            </el-form-item>
            <el-form-item label="薪资">
              {{ dataForm.minSalary }}K - {{ dataForm.maxSalary }}K
            </el-form-item>
            <el-form-item label="福利待遇">{{ welfareName }}</el-form-item>
            <el-form-item class="textbox" label="岗位描述">
              {{ dataForm.postShow }}
            </el-form-item>
          </div>
        </el-form>
        <el-form v-if="!isCheck">
          <h3 class="header blue">审核状态</h3>
          <div class="mine_table">
            <el-form-item label="审核状态">
              {{
                dataForm.auditStatus == 1
                  ? '待审核'
                  : dataForm.auditStatus == 2
                  ? ' 审核通过'
                  : '审核不通过'
              }}
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div v-if="isCheck" class="panelFoot">
        <el-form ref="form" :model="checkForm">
          <el-form-item label="审核意见" prop="remark">
            <el-input
              v-model="checkForm.remark"
              placeholder="请输入审核意见"
              rows="5"
              type="textarea"
            />
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="success" @click="submit(2)">审核通过</el-button>
          <el-button type="danger" @click="submit(3)">审核不通过</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { check, getData } from '@/api/recruit/post'
  import { list as getDictList } from '@/api/sysDict'
  import { getDict as getWelfareDict } from '@/api/dict/welfare'
  import { getDict as getPositionDict } from '@/api/dict/position'
  import _ from 'lodash'
  export default {
    name: 'PostViewer',
    data() {
      return {
        dataForm: {},
        checkForm: {
          id: '',
        },
        dictMap: {},
        visible: false,
        isCheck: false,
      }
    },
    computed: {
      title() {
        if (this.isCheck) return '审核岗位'
        else return '查看岗位'
      },
      welfareName() {
        if (this.dataForm.welfareId) {
          return this.getWelfare(this.dataForm.welfareId)
        } else {
          return ''
        }
      },
    },
    mounted() {
      this.getDict('qualification')
      this.getDict('work_years')
      this.getPositionDict()
    },
    methods: {
      async getDict(name) {
        const { code, data, msg } = await getDictList({ dictType: name })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        } else if (data.length) {
          let map = {}
          // let items = []
          data.forEach((d) => {
            if (d.pid !== '0') {
              map[d.dictValue] = d.dictName
              // items.push(d)
            }
          })
          this.$set(this.dictMap, name, map)
          // this.$set(this.dictList, name, items)
        }
      },
      async getPositionDict() {
        const { code, data, msg } = await getPositionDict()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this.dictMap, 'position', data.map)
        // this.$set(this.dictList, 'position', list)
      },
      // 获取福利字典
      async getWelfareMap(id) {
        const { code, data, msg } = await getWelfareDict({ id })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        if (data && data.map) {
          this.$set(this.dictMap, 'welfare', data.map)
        }
      },
      getWelfare(welfare) {
        if (welfare) {
          let list = welfare.split(',')
          let ret = list.map((item) => {
            if (
              item &&
              this.dictMap['welfare'] &&
              this.dictMap['welfare'][item]
            ) {
              return this.dictMap['welfare'][item]
            }
          })
          return ret.join('；')
        } else {
          return ''
        }
      },
      init(id, isCheck) {
        this.isCheck = isCheck
        if (id) this.getData(id)
        this.visible = true
      },
      close() {
        if (this.isCheck) this.$refs['form'].resetFields()
        this.checkForm = this.$options.data().checkForm
        this.visible = false
      },
      async getData(id) {
        const { code, data, msg } = await getData({ id })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this, 'dataForm', data)
        this.getWelfareMap(data.userId)
        this.$set(this.checkForm, 'id', data.id)
      },
      async submit(status) {
        let form = _.cloneDeep(this.checkForm)
        form.auditStatus = status
        const { code, msg } = await check(form)
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        } else {
          this.$baseMessage(
            `${this.title}操作成功`,
            'success',
            'vab-hey-message-success'
          )
        }
        this.$emit('fetch-data')
        this.close()
      },
    },
  }
</script>
<style lang="scss">
  .drawerBox .el-dialog__body {
    padding: 5px 16px;
  }
  .drawerPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .panelFoot {
      width: 100%;
      padding: 20px;
      margin: 0;
      margin-top: auto;

      .btn {
        text-align: center;
      }
    }

    .panelBody {
      width: 100%;
      flex: 2;
      padding: 0 15px;
      margin: 0;
      margin-bottom: auto;

      .header {
        line-height: 28px;
        margin-bottom: 16px;
        margin-top: 18px;
        padding-bottom: 4px;
      }

      .blue {
        color: #478fca !important;
        border-bottom: 1px solid #dcebf7;
      }

      .green {
        color: #69aa46 !important;
        border-bottom: 1px solid #cce2c1;
      }

      .mine_table {
        border: 1px solid #dcebf7;
        margin-bottom: 10px;

        .textbox {
          position: relative;
          .el-form-item__label {
            display: block;
            height: 100%;
          }

          .el-form-item__content {
            overflow: auto;
            max-height: 10em;
          }
        }

        .el-form-item {
          margin-bottom: 1px;
        }

        .el-form-item__label {
          color: #336199;
          background-color: #edf3f4;
          width: 110px;
          text-align: right;
        }
        .el-form-item__content {
          margin-left: 110px;
          padding: 0 15px;
          border-bottom: 1px dotted #dcebf7;

          &::nth-last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
</style>

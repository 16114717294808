<template>
  <el-dialog :title="title" :visible.sync="visible" width="30%" @close="close">
    <el-form ref="form" label-width="100px" :model="dataForm" :rules="rules">
      <el-form-item label="名称" prop="dictValue">
        <el-input v-model="dataForm.dictValue" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item
        v-if="!dataForm.id"
        :error="industryError"
        label="行业"
        prop="industryId"
      >
        <el-popover
          ref="menuListPopover"
          v-model="menuListVisible"
          placement="bottom-start"
          trigger="click"
        >
          <el-tree
            accordion
            :data="industryList"
            :expand-on-click-node="false"
            :highlight-current="true"
            node-key="id"
            :props="{ label: 'dictValue', children: 'children' }"
            @current-change="industryTreeChanged"
          />
        </el-popover>
        <el-input
          v-model="industryName"
          v-popover:menuListPopover
          class="treeInput"
          placeholder="请选择行业"
          :readonly="true"
        >
          <template #suffix>
            <i
              v-if="dataForm.industryId"
              class="el-icon-circle-close el-input__icon"
              @click.stop="resetIndustry()"
            ></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="!dataForm.id" label="上级层级">
        <el-select
          v-model="dataForm.pid"
          clearable
          :disabled="!dataForm.industryId"
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.dictValue"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="dataForm.remark" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
  import { save, update, getData, getDict } from '@/api/dict/equipment'
  import { getList } from '@/api/dict/industry'
  import _ from 'lodash'
  export default {
    name: 'EquipmentEdit',
    data() {
      return {
        dataForm: {
          id: null,
          pid: '',
        },
        menuListVisible: false,
        industryName: '',
        industryError: '',
        industryList: [],
        brandList: [],
        visible: false,
        rules: {
          dictValue: [
            { required: true, trigger: 'blur', message: '请输入名称' },
          ],
          industryId: [
            { required: true, trigger: 'submit', message: '请选择行业' },
          ],
        },
      }
    },
    computed: {
      title() {
        if (!this.dataForm.id) return '新增'
        else return '修改'
      },
    },
    methods: {
      showEdit(row) {
        if (row && row.id) {
          // this.form = _.cloneDeep(row)
          this.getData(row.id)
        } else {
          this.getIndustryList()
        }
        this.visible = true
      },
      async getIndustryList() {
        const { code, data, msg } = await getList()
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        } else {
          this.$set(this, 'industryList', data)
        }
      },
      async getBrandList(industryId) {
        const { code, data, msg } = await getDict({ industryId, dictLevel: 1 })
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
        } else {
          this.$set(this, 'brandList', data)
        }
      },
      resetIndustry() {
        this.dataForm.pid = ''
        this.industryName = ''
        this.dataForm.industryId = 0
      },
      industryTreeChanged(data) {
        if (data.dictLevel === 4) {
          // 创建品牌
          this.industryError = ''
          this.dataForm.pid = ''
          this.industryName = data.dictValue
          this.menuListVisible = false
          this.getBrandList(data.id)
          this.dataForm.industryId = data.id
        } else {
          this.menuListVisible = false
          this.industryError = '请选择第4级创建品牌'
        }
      },
      close() {
        this.$refs['form'].resetFields()
        this.dataForm = this.$options.data().dataForm
        this.industryName = ''
        this.visible = false
      },
      async getData(id) {
        const { code, data, msg } = await getData(id)
        if (code !== 0) {
          this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          return
        }
        this.$set(this, 'dataForm', data)
      },
      async checkForm(name) {
        return new Promise((resolve, reject) => {
          this.$refs[name].validate((valid) => {
            return valid ? resolve(true) : reject(new Error('请按表单提示修改'))
          })
        })
      },
      async save() {
        if (this.industryError) return
        const ret = await this.checkForm('form')
        if (ret) {
          let func = this.dataForm.id ? update : save
          let form = _.cloneDeep(this.dataForm)
          form.dictValue = _.trim(form.dictValue)
          const { code, msg } = await func(form)
          if (code !== 0) {
            this.$baseMessage(msg, 'error', 'vab-hey-message-error')
          } else {
            this.$baseMessage(
              `${this.title}操作成功`,
              'success',
              'vab-hey-message-success'
            )
          }
          this.$emit('fetch-data')
          this.close()
        }
      },
    },
  }
</script>
<style lang="scss">
  .treeInput {
    > input {
      cursor: pointer;
    }
  }
</style>

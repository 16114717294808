var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c("el-form-item", { attrs: { label: "用户名" } }, [
            _c("span", [_vm._v(_vm._s(_vm.form.username))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "原密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "newPassword" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.form.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newPassword", $$v)
                  },
                  expression: "form.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认密码", prop: "comfirmPassword" } },
            [
              _c("el-input", {
                attrs: { type: "password" },
                model: {
                  value: _vm.form.comfirmPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "comfirmPassword", $$v)
                  },
                  expression: "form.comfirmPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
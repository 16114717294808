<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="编码" prop="paramCode">
        <el-input v-model="form.paramCode" />
      </el-form-item>
      <el-form-item label="值" prop="paramValue">
        <el-input v-model="form.paramValue" :rows="5" type="textarea" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import { doPost, doPut } from '@/api/params'

  export default {
    name: 'ParamsEdit',
    data() {
      return {
        form: {
          id: '',
          paramCode: '',
          paramValue: '',
          remark: '',
        },
        rules: {
          paramCode: [
            { required: true, trigger: 'blur', message: '请输入编码' },
          ],
          paramValue: [
            { required: true, trigger: 'blur', message: '请输入值' },
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '编辑'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.form.id) {
              await doPut(this.form)
              this.$baseMessage(
                '编辑成功',
                'success',
                'vab-hey-message-success'
              )
            } else {
              await doPost(this.form)
              this.$baseMessage(
                '添加成功',
                'success',
                'vab-hey-message-success'
              )
            }
            this.$emit('fetch-data')
            this.close()
          }
        })
      },
    },
  }
</script>

import request from '@/utils/request'
import qs from 'qs'
import { baseURL } from '@/config'
import { getToken } from '@/utils/token'

export const page = (params, cfg) =>
  request.get('/resume/page4pc', { ...cfg, params })
export const remove = (data, cfg) => request.delete('/resume', { ...cfg, data })
export const getData = (id, params, cfg) =>
  request.get(`/resume/${id}`, { ...cfg, params })
export const examineFault = (params, cfg) =>
  request.put('/resume/examineFault', null, { ...cfg, params })
export const examineSuccess = (params, cfg) =>
  request.put('/resume/examineSuccess', null, { ...cfg, params })
export function exportExcel(params) {
  let token = getToken()
  let str = qs.stringify({ ...params, token })
  return `${baseURL}/resume/export?${str}`
}
